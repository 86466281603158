import { createGlobalStyle } from 'styled-components';

import { primitive } from '../../designTokens/primitives';
import { rootVars } from '../base';
import { mediaQuery } from '../mediaQueries';

export const GlobalStyles = createGlobalStyle`
  ${rootVars}
  html {
    --font-hero: ${primitive.plk.$hero};
    --font-headerOne: ${primitive.plk.$headerOne};
    --font-headerTwo: ${primitive.plk.$headerTwo};
    --font-headerThree: ${primitive.plk.$headerThree};
    --font-copyOne: ${primitive.plk.$copyOne};
    --font-copyTwo: ${primitive.plk.$copyTwo};

    @media ${mediaQuery.desktopSmall} {
      --font-headerOne: ${primitive.plk.$headerOneDesktop};
      --font-headerTwo: ${primitive.plk.$headerTwoDesktop};
      --font-headerThree: ${primitive.plk.$headerThreeDesktop};
      --font-copyOne: ${primitive.plk.$copyOneDesktop};
      --font-copyTwo: ${primitive.plk.$copyTwoDesktop};
    }

    @media ${mediaQuery.desktopLarge} {
      --font-hero: ${primitive.plk.$heroDesktop};
    }
  }
`;
