import { IFontWeight } from '../types';

export const fontWeight: IFontWeight = {
  headings: 'bold',
  primary: 'bold',
  secondary: '500',
  tertiary: 'normal',
  link: 'bold',
  button: 'bold',
};
