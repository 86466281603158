import { IFontFace, SupportedFontTypes, SupportedFonts } from '../types';

export const fontFace: IFontFace[] = [
  {
    name: 'chicken_sans_bold',
    sources: [{ source: SupportedFonts.ChickenSansBold, type: SupportedFontTypes.woff2 }],
    style: 'normal',
    weight: 'normal',
    display: 'swap',
  },
  {
    name: 'chicken_sans',
    sources: [{ source: SupportedFonts.ChickenSansRegular, type: SupportedFontTypes.woff2 }],
    style: 'normal',
    weight: 'normal',
    display: 'swap',
  },
];
