import { IFontSize } from '../types';

export const fontSize: IFontSize = {
  headings: {
    H1: '6rem',
    H2: '3.75rem',
    H3: '3rem',
    H4: '2.25rem',
    H5: '1.625rem',
    H6: '1.375rem',
  },

  subtitle: {
    LARGE: '1.125rem',
    SMALL: '1rem',
  },

  body: {
    LARGE: '1rem',
    SMALL: '0.875rem',
  },

  button: {
    LARGE: '1rem',
    SMALL: '0.875rem',
  },

  caption: '0.8rem',
  overline: '0.625rem',
  link: '1rem',
};
