import { ILetterSpacing } from '../types';

export const letterSpacing: ILetterSpacing = {
  headings: {
    H1: '-2px',
    H2: 'normal',
    H3: 'normal',
    H4: 'normal',
    H5: 'normal',
    H6: 'normal',
  },
  button: 'normal',
  body: 'normal',
  overline: '0.5px',
};
