import { IColor } from '../types/color';

export const color: IColor = {
  /* PLK: Primary Orange */
  primary: '#F25600',
  /* Vivid Orange */
  secondary: '#BE5A09',
  tertiary: '#D90F06',
  black: '#000000',
  /* Salt White */
  brightWhite: '#FFFFFF',
  /* Salt White */
  white: '#FFFFFF',
  /* Gainsboro */
  buttonsDisabledBackground: '#d0d0d0',
  buttonsDisabledLabel: '#838383',
  /* Vivid Orange */
  buttonsPrimaryHover: '#BE5A09',
  /* 15% primary */
  buttonsSecondaryHover: '#FCDDCC',
  /* 25% primary */
  buttonsFocused: '#EBCDB2',
  /* PLK: Primary Orange */
  buttonsPrimaryActive: '#f25600',
  /* PLK: Primary Orange */
  buttonsSecondaryActive: '#f25600',
  /* NOLA Teal */
  inputsFocused: '#00B2A9',
  statusValidated: '#5E8036',
  /* Spicy Red */
  statusError: '#D90F06',
  /* Spicy Red */
  statusPrice: '#D90F06',
  /* Butter Yellow */
  statusSuccess: '#FFC800',
  /* Darkest to Lightest */
  100: '#1F1F1F',
  200: '#323231',
  300: '#4A4A4A',
  400: '#696969',
  500: '#777777',
  600: '#959595',
  700: '#D0D0D0',
  800: '#EAE7E5',
  /* Light Grayish Orange */
  900: '#F5F1EF',
};
