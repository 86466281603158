import { commonIcons } from '../common/icon';
import { IIcon } from '../types/icon';

export const icon: IIcon = {
  ...commonIcons,
  catering: 'cateringPLK',
  cluckNorris: 'cluckNorris',
  cluckNorrisLoveThatChicken: 'cluckNorrisLoveThatChicken',
  cluckNorrisSaxophone: 'cluckNorrisSaxophone',
  coffeeCupFilled: 'noop',
  combo: 'combo',
  delivery: 'deliveryPLK',
  familyMeals: 'familyMeals',
  hotDrink: 'noop',
  kidsMeal: 'kidsMeal',
  logo: 'popeyesLogo',
  mobileNavHome: 'mobileNavHomePLK',
  order: 'book',
  plkRewards: 'plkRewards',
  rewards: 'plkRewards',
  seafood: 'seafood',
  sides: 'sides',
  signatureChicken: 'signatureChicken',
  tendersAndWings: 'tendersAndWings',
};
